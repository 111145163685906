import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

import { Icon } from '@types';
//import svg from "intrepid-blog-logo.svg";

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg
        width="192"
        height="23"
        viewBox="0 0 192 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
        <g clipPath="url(#clip0)">
        <path
            d="M 22.625 18.075 L 22.625 4.925 L 25.55 4.925 L 25.8 6.65 L 25.975 6.65 A 5.469 5.469 0 0 1 27.543 5.268 
            A 5.003 5.003 0 0 1 30.075 4.625 Q 31.854 4.625 32.967 5.474 A 3.645 3.645 0 0 1 33.262 5.725 A 3.309 3.309 0 0 1 34.077 6.994 
            Q 34.273 7.517 34.358 8.165 A 8.307 8.307 0 0 1 34.425 9.25 L 34.425 18.075 L 31 18.075 L 31 9.975 Q 31 9.245 30.796 8.755 
            A 1.614 1.614 0 0 0 30.425 8.2 Q 29.95 7.746 29.101 7.667 A 4.05 4.05 0 0 0 28.725 7.65 Q 27.575 7.65 26.813 8.462 
            A 2.79 2.79 0 0 0 26.055 10.29 A 3.64 3.64 0 0 0 26.05 10.475 L 26.05 18.075 L 22.625 18.075 Z M 67.2 11.5 L 67.2 12.3 
            L 58.55 12.3 A 7.861 7.861 0 0 0 58.626 13.212 Q 58.772 14.209 59.185 14.799 A 2.234 2.234 0 0 0 59.25 14.887 
            A 1.983 1.983 0 0 0 60.17 15.535 Q 60.508 15.657 60.928 15.7 A 4.793 4.793 0 0 0 61.425 15.725 Q 62.5 15.725 63.163 15.125 
            A 1.952 1.952 0 0 0 63.757 14.078 A 2.965 2.965 0 0 0 63.825 13.425 L 67.2 13.425 A 6.065 6.065 0 0 1 67.03 14.908 
            A 3.948 3.948 0 0 1 65.6 17.137 A 5.629 5.629 0 0 1 63.543 18.121 Q 62.748 18.327 61.814 18.366 
            A 10.598 10.598 0 0 1 61.375 18.375 Q 58.2 18.375 56.638 16.7 A 5.119 5.119 0 0 1 55.583 14.919 Q 55.075 13.51 55.075 11.5 
            A 12.643 12.643 0 0 1 55.206 9.617 Q 55.351 8.653 55.659 7.882 A 5.012 5.012 0 0 1 56.637 6.3 Q 57.971 4.871 60.478 4.661 
            A 10.767 10.767 0 0 1 61.375 4.625 Q 63.514 4.625 64.865 5.528 A 4.349 4.349 0 0 1 65.75 6.313 A 5.205 5.205 0 0 1 66.689 7.983 
            Q 67.2 9.422 67.2 11.5 Z M 100.225 6.45 L 100.375 6.45 L 100.375 0 L 103.8 0 L 103.8 18.075 L 100.875 18.075 L 100.65 16.35 
            L 100.475 16.35 Q 99.85 17.35 98.8 17.863 Q 97.75 18.375 96.475 18.375 A 5.844 5.844 0 0 1 94.823 18.154 
            A 4.367 4.367 0 0 1 92.6 16.663 Q 91.613 15.433 91.334 13.295 A 14.131 14.131 0 0 1 91.225 11.475 Q 91.225 9.345 91.77 7.872 
            A 5.388 5.388 0 0 1 92.612 6.35 A 4.433 4.433 0 0 1 95.842 4.643 A 5.785 5.785 0 0 1 96.3 4.625 A 6.286 6.286 0 0 1 97.51 4.736 
            A 4.831 4.831 0 0 1 98.638 5.1 A 3.911 3.911 0 0 1 99.778 5.896 A 3.635 3.635 0 0 1 100.225 6.45 Z M 69.85 22.4 L 69.85 4.925 
            L 72.775 4.925 L 73 6.65 L 73.175 6.65 Q 73.8 5.65 74.85 5.137 Q 75.9 4.625 77.175 4.625 A 5.844 5.844 0 0 1 78.827 4.846 
            A 4.367 4.367 0 0 1 81.05 6.337 Q 82.037 7.567 82.316 9.705 A 14.131 14.131 0 0 1 82.425 11.525 Q 82.425 13.655 81.88 15.128 
            A 5.388 5.388 0 0 1 81.038 16.65 A 4.433 4.433 0 0 1 77.808 18.357 A 5.785 5.785 0 0 1 77.35 18.375 
            A 6.286 6.286 0 0 1 76.14 18.264 A 4.831 4.831 0 0 1 75.012 17.9 A 3.911 3.911 0 0 1 73.872 17.104 
            A 3.635 3.635 0 0 1 73.425 16.55 L 73.275 16.55 L 73.275 22.4 L 69.85 22.4 Z M 37.975 14.85 L 37.975 7.725 L 36.4 7.725 
            L 36.4 4.925 L 38.05 4.925 L 38.625 1.225 L 41.4 1.225 L 41.4 4.925 L 43.675 4.925 L 43.675 7.725 L 41.4 7.725 L 41.4 14.5 
            A 1.548 1.548 0 0 0 41.453 14.925 Q 41.59 15.402 42.077 15.529 A 1.584 1.584 0 0 0 42.475 15.575 L 43.675 15.575 L 43.675 17.975 
            A 5.43 5.43 0 0 1 43.326 18.084 Q 43.134 18.138 42.911 18.187 A 11.475 11.475 0 0 1 42.613 18.25 A 6.942 6.942 0 0 1 41.941 18.349 
            A 5.404 5.404 0 0 1 41.425 18.375 A 5.39 5.39 0 0 1 40.467 18.296 Q 39.944 18.201 39.539 17.993 A 2.329 2.329 0 0 1 38.775 17.375 
            A 3.586 3.586 0 0 1 38.055 15.762 A 4.979 4.979 0 0 1 37.975 14.85 Z M 46.225 18.075 L 46.225 4.925 L 49.15 4.925 L 49.425 7.05 
            L 49.6 7.05 Q 50.025 5.925 50.7 5.275 Q 51.375 4.625 52.475 4.625 A 4.012 4.012 0 0 1 53.183 4.685 A 3.141 3.141 0 0 1 53.775 4.85 
            L 53.775 8.05 L 52.825 8.05 A 4.847 4.847 0 0 0 51.934 8.126 Q 51.441 8.219 51.062 8.423 A 2.188 2.188 0 0 0 50.4 8.962 
            A 2.864 2.864 0 0 0 49.914 9.859 Q 49.774 10.267 49.708 10.768 A 7.368 7.368 0 0 0 49.65 11.725 L 49.65 18.075 L 46.225 18.075 Z 
            M 15.65 18.075 L 15.65 4.925 L 19.075 4.925 L 19.075 18.075 L 15.65 18.075 Z M 85.1 18.075 L 85.1 4.925 L 88.525 4.925 
            L 88.525 18.075 L 85.1 18.075 Z M 0 22.075 L 0 19.625 L 13.9 19.625 L 13.9 22.075 L 0 22.075 Z M 105.575 22.075 L 105.575 19.625 
            L 119.475 19.625 L 119.475 22.075 L 105.575 22.075 Z M 78.9 11.725 L 78.9 11.275 Q 78.9 9.52 78.315 8.608 A 2.404 2.404 0 0 0 78.25 8.513 
            Q 77.668 7.695 76.383 7.61 A 4.661 4.661 0 0 0 76.075 7.6 Q 73.275 7.6 73.275 11.275 L 73.275 11.675 A 7.284 7.284 0 0 0 73.388 13.023 
            Q 73.811 15.256 75.81 15.391 A 3.917 3.917 0 0 0 76.075 15.4 Q 77.052 15.4 77.67 15.026 A 1.938 1.938 0 0 0 78.25 14.487 
            Q 78.874 13.611 78.899 11.871 A 10.215 10.215 0 0 0 78.9 11.725 Z M 100.375 11.725 L 100.375 11.325 A 7.284 7.284 0 0 0 100.262 9.977 
            Q 99.839 7.744 97.84 7.609 A 3.917 3.917 0 0 0 97.575 7.6 Q 96.598 7.6 95.98 7.974 A 1.938 1.938 0 0 0 95.4 8.513 
            Q 94.776 9.389 94.751 11.129 A 10.215 10.215 0 0 0 94.75 11.275 L 94.75 11.725 Q 94.75 13.48 95.335 14.392 A 2.404 2.404 0 0 0 95.4 14.487 
            Q 95.982 15.305 97.267 15.39 A 4.661 4.661 0 0 0 97.575 15.4 Q 100.375 15.4 100.375 11.725 Z M 58.6 10.025 L 63.65 10.025 
            A 4.77 4.77 0 0 0 63.552 9.013 Q 63.234 7.553 61.877 7.337 A 3.198 3.198 0 0 0 61.375 7.3 A 3.991 3.991 0 0 0 60.625 7.366 
            Q 60.199 7.447 59.869 7.63 A 1.969 1.969 0 0 0 59.412 7.975 Q 58.894 8.504 58.689 9.462 A 5.621 5.621 0 0 0 58.6 10.025 Z 
            M 15.65 3.25 L 15.65 0 L 19.075 0 L 19.075 3.25 L 15.65 3.25 Z M 85.1 3.25 L 85.1 0 L 88.525 0 L 88.525 3.25 L 85.1 3.25 Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="191.156" height="23" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <svg
        width="18"
        height="23"
        viewBox="0 0 18 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
        >
          <path
            d="M 22.588 21.738 L 18.604 21.738 A 1.39 1.39 0 0 1 18.581 21.632 Q 18.534 21.375 18.475 20.743 A 38.281 38.281 0 0 1 18.464 
            20.632 A 24.844 24.844 0 0 1 18.418 20.059 Q 18.401 19.802 18.392 19.577 A 10.85 10.85 0 0 1 18.384 19.16 L 18.325 19.16 
            A 4.644 4.644 0 0 1 15.023 21.84 A 7.643 7.643 0 0 1 13.257 22.031 Q 10.488 22.031 8.979 19.871 A 7.457 7.457 0 0 1 7.975 17.741 
            Q 7.676 16.75 7.554 15.557 A 17.072 17.072 0 0 1 7.471 13.828 A 15.767 15.767 0 0 1 7.626 11.541 Q 7.802 10.343 8.178 9.37 
            A 6.893 6.893 0 0 1 9.06 7.742 A 5.126 5.126 0 0 1 12.814 5.634 A 7.254 7.254 0 0 1 13.564 5.596 Q 15.249 5.596 16.472 6.299 
            A 4.52 4.52 0 0 1 18.178 8.051 A 5.633 5.633 0 0 1 18.354 8.394 L 18.384 8.394 L 18.354 5.786 L 18.354 0 L 22.471 0 L 22.471 18.281 
            A 44.519 44.519 0 0 0 22.487 19.454 Q 22.501 19.974 22.526 20.545 A 81.445 81.445 0 0 0 22.588 21.738 Z M 4.116 21.738 L 0 21.738 
            L 0 5.889 L 4.116 5.889 L 4.116 21.738 Z M 18.069 10.95 A 4.865 4.865 0 0 0 17.556 9.778 Q 16.777 8.519 15.324 8.405 
            A 3.778 3.778 0 0 0 15.029 8.394 A 3.211 3.211 0 0 0 13.97 8.559 Q 13.118 8.855 12.601 9.68 A 3.401 3.401 0 0 0 12.568 9.734 
            A 4.549 4.549 0 0 0 12.118 10.794 Q 11.811 11.835 11.769 13.34 A 17.545 17.545 0 0 0 11.763 13.828 
            A 13.422 13.422 0 0 0 11.877 15.66 Q 12.37 19.219 15 19.219 A 2.929 2.929 0 0 0 16.293 18.94 Q 16.901 18.646 17.35 18.04 
            A 3.857 3.857 0 0 0 17.52 17.791 A 5.05 5.05 0 0 0 18.073 16.52 Q 18.413 15.353 18.413 13.726 Q 18.413 12.101 18.069 10.95 
            Z M 4.116 3.032 L 0 3.032 L 0 0 L 4.116 0 L 4.116 3.032 Z"
            fill={fill}
          />
        </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
